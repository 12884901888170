import React from "react";
import { Link } from "gatsby";

import Button from "../button/Button";

import * as styles from "./Join.module.css";

const Join = ( props ) => {

    return (
        <div className={styles.wrapper}>
            <div className={`content ${styles.content}`}>
                <h1>Become a DBHS Member</h1>
                <p>For only £5 per year, gain access to events, discounts and community resources.</p>
                <Link
                    to="/about"
                >
                    <Button>
                        Read more
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default Join;