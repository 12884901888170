import React from "react";

import logoFull from "../logo/logo-full.svg";

import * as styles from "./Menu.module.css";

const Menu = ({ children }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img
                        src={logoFull} 
                        alt="Dalgety Bay Horticultural Society logo"
                    />
                </div>
                {children}
            </div>
        </div>
    )
}

export default Menu;