import React, { useState } from "react"
import { Link } from "gatsby";
import useBodyClass from "../../actions/toggleBodyClass";

import { useMediaQuery } from "react-responsive";

import Button from "../button/Button";
import Menu from "../menu/Menu";

import classNames from "classnames/bind";
import * as styles from "./Navigation.module.css";

const navigation = [
    {
        name: "Home",
        url: "/",
    },
    // {
    //     name: "Calendar",
    //     url: "/calendar/",
    // },
    {
        name: "About DBHS",
        url: "/about/",
        menu: [
            {
                name: "What we do",
                url: "/about/",
            },
            {
                name: "Committee",
                url: "/committee/",
            },
            {
                name: "Allotments",
                url: "/allotments/",
            },
            {
                name: "Tips",
                url: "/tips/",
            },
            {
                name: "Resources",
                url: "/resources/",
            },
            {
                name: "Newsletters",
                url: "/newsletters/",
            },
            {
                name: "Gallery",
                url: "/gallery/",
            },
        ],
    },
    {
        name: "Annual Show",
        url: "/show/",
    },
    {
        name: "Get in touch",
        url: "/contact/",
    },
];

function Links() {
    const cx = classNames.bind(styles);

    return (
        <nav className={styles.wrapper} role="navigation">
            <ul className={`list-normal ${styles.nav}`}>
                {navigation.map(nav => (
                    <li
                        key={nav.name}
                        className={cx({"hasSubnav" : nav.menu && nav.menu.length > 0})}
                    >
                        <Link
                            activeClassName={styles.current}
                            to={nav.url}
                        >
                            {nav.name}
                        </Link>

                        {nav.menu &&
                            <ul className={styles.subnav}>
                                {nav.menu.map(subnav => (
                                    <li key={subnav.name}>
                                        <Link
                                            activeClassName={styles.current}
                                            to={subnav.url}
                                        >
                                            {subnav.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        }
                    </li>
                ))}
            </ul>
        </nav>
    )
}


function Navigation(props) {
    const cx = classNames.bind(styles);
    
    const isFull = useMediaQuery({ query: "(min-width: 1100px)" });

    const [toggle, setToggle] = useState(false);

    const triggerToggle = () => {
        setToggle( !toggle );
    }

    useBodyClass(`menu-${toggle}`);

    return (
        <React.Fragment>
            {isFull ?
                <Links />
            :
                <React.Fragment>
                    {toggle ?
                        <Menu>
                            <Links />

                            <Button
                                onClick={triggerToggle}
                                className={cx("button", "close")}
                            >
                                Close
                            </Button>
                        </Menu>
                    :
                        <Button
                            onClick={triggerToggle}
                            className={styles.button}
                        >
                            Menu
                        </Button>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    ); 
} 
  
export default Navigation;