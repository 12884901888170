import React from "react"
import { useMediaQuery } from "react-responsive";

import icon from "./icon.svg";
import iconFooter from "./icon-outline.svg";
import logoSmall from "./logo-small.svg";
import logoMedium from "./logo-medium.svg";
import logoFull from "./logo-full.svg";

import classNames from "classnames/bind";
import * as styles from "./Logo.module.css";

export default function Logo(props) {

    const cx = classNames.bind(styles);

    const isSmall = useMediaQuery({ query: "(min-width: 500px)"});
    const isMedium = useMediaQuery({ query: "(min-width: 720px)" });
    const isFull = useMediaQuery({ query: "(min-width: 1280px)" });

    const alt = "Dalgety Bay Horticultural Society logo image";

    // className={cx({"hasSubnav" : nav.menu && nav.menu.length > 0})}

    return (
        <div className={cx({"footer" : props.isFooter}, "logo")}>
            {props.isFooter ?
                <img src={iconFooter} alt={alt} />
            : isFull ?
                <img src={logoFull} alt={alt} />
            : isMedium ?
                <img src={logoMedium} alt={alt} />
            : isSmall ?
                <img src={logoSmall} alt={alt} />
            :
                <img src={icon} alt={alt} />
            }
        </div>
    )
}