import * as React from "react"

import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Join from "../components/join/Join";

import classNames from "classnames/bind";
import "../styles/global.css";
import * as styles from "./Layout.module.css";


// markup
const Layout = (props) => {
    const cx = classNames.bind(styles);

    const classes = cx(
        "main",
        props.className
    );


    return (
        <div
            className={`wrapper ${styles.wrapper}`}
        >
            <Helmet>
                <title>{props.title ? props.title : "Dalgety Bay Horticultural Society"}</title>
            </Helmet>

            <Header />

            {props.isHome &&
                <Join />
            }            
            <main className={classes}>
                <div>
                    {props.isBlog &&
                        <p><Link to="/">Back to index</Link></p>
                    }
                    {props.isEvent &&
                        <p><Link to="/calendar/">Back to Calendar</Link></p>
                    }
                    <h1>
                        {props.isCancelled && <span>Cancelled: </span>}
                        {props.title}
                    </h1>
                </div>
                {props.children}
            </main>

            <Footer />
        </div>
    )
}

export default Layout