import React from "react";
import { Link } from "gatsby";

import Logo from "../logo/Logo";

import * as styles from "./Footer.module.css";

const navigation = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Calendar",
        url: "/calendar/",
    },
    {
        name: "About DBHS",
        url: "/about/",
    },
    {
        name: "Annual Show",
        url: "/show/",
    },
    {
        name: "Get in touch",
        url: "/contact/",
    },
];

const Footer = () => {
    return (
        <footer className={styles.wrapper}>
            <div className={`content ${styles.content}`}>
                <div>
                    <div className={`flex-inline ${styles.inner}`}>
                        <Logo isFooter />
                        <nav className={styles.nav}>
                            <ul className={`list-normal ${styles.links}`}>
                                {navigation.map(nav => (
                                    <li
                                        key={nav.name}
                                        className={styles.link}
                                    >
                                        <Link
                                            activeClassName={styles.current}
                                            to={nav.url}
                                        >
                                            {nav.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>

                <p className={styles.copyright}>
                    &copy; Dalgety Bay Horticultural Society 2021. Logo and website by <a className={styles.bryoz} href="https://github.com/bryoz" rel="noreferrer" target="_blank">bry<span>oz</span></a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;