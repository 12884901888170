import React from "react";

import Navigation from "../navigation/Navigation";
import Logo from "../logo/Logo";

import * as styles from "./Header.module.css";


const Header = () => {
    return (
        <header className={styles.wrapper}>
            <div className={`flex-between content ${styles.content}`}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <Navigation />
            </div>
        </header>
    );
};

export default Header;